import { isPlatformBrowser } from '@angular/common';
import {
  ApplicationConfig,
  Injectable,
  PLATFORM_ID,
  effect,
  inject,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { ApolloQueryResult } from '@apollo/client';
import { TranslocoService } from '@jsverse/transloco';
import { patchState, signalState } from '@ngrx/signals';
import {
  GetNavigationQuery,
  LocalizedFragment,
  NodeFragment,
} from 'generated/types.graphql-gen';
import { MonoTypeOperatorFunction, pipe, tap } from 'rxjs';

export function extractLocalized<T>(): MonoTypeOperatorFunction<T> {
  const navigationService = inject(NavigationService);

  return pipe(
    tap((r: any) => {
      try {
        if (
          r &&
          r.hasOwnProperty('data') &&
          r.data?.hasOwnProperty('entry') &&
          r.data?.entry?.hasOwnProperty('localized')
        ) {
          navigationService.updateLangSwitch(r.data.entry.localized);
        }
      } catch (error) {
        console.error(error);
      }
    }),
  );
}

export function extractActiveLang<T>(): MonoTypeOperatorFunction<T> {
  const transloco = inject(TranslocoService);
  return pipe(
    tap((r: any) => {
      try {
        if (
          r &&
          r.hasOwnProperty('data') &&
          r.data?.hasOwnProperty('entry') &&
          r.data?.entry?.hasOwnProperty('siteId')
        ) {
          transloco.setActiveLang(r.data.entry.siteId === 2 ? 'en' : 'de');
        }
      } catch (error) {
        console.error(error);
      }
    }),
  );
}

export function extractNavigation<T>(): MonoTypeOperatorFunction<T> {
  const navigationService = inject(NavigationService);
  return pipe(
    tap((r: any) => {
      try {
        if (
          r &&
          r.hasOwnProperty('data') &&
          r.data?.hasOwnProperty('mainNavigation') &&
          r.data?.hasOwnProperty('iconNavigation') &&
          r.data?.hasOwnProperty('mainSubNavigation') &&
          r.data?.hasOwnProperty('footerNavigation') &&
          r.data?.hasOwnProperty('footerSubNavigation')
        ) {
          navigationService.updateNavigation(r);
        } else {
          throw new Error('It seems the data is not relate');
        }
      } catch (error) {
        console.error(error);
      }
    }),
  );
}

type NavigationState = {
  langSwitch: Array<LocalizedFragment>;
  iconNavigation: Array<NodeFragment | null>;
  mainNavigation: Array<NodeFragment | null>;
  mainSubNavigation: Array<
    (NodeFragment & { children: Array<NodeFragment | null | undefined> }) | null
  >;
  footerNavigation: Array<
    (NodeFragment & { children: Array<NodeFragment | null> }) | null
  >;
  footerSubNavigation: Array<NodeFragment | null>;
  showMainNavigation: boolean;
};

const navigationState: NavigationState = {
  langSwitch: [],
  iconNavigation: [],
  mainNavigation: [],
  mainSubNavigation: [],
  footerNavigation: [],
  footerSubNavigation: [],
  showMainNavigation: false,
};

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  readonly #state = signalState(navigationState);

  readonly #router = inject(Router);
  readonly #routerEvents = toSignal(this.#router.events);
  readonly #platformId = inject(PLATFORM_ID);

  readonly langSwitch = this.#state.langSwitch;
  readonly mainNavigation = this.#state.mainNavigation;
  readonly iconNavigation = this.#state.iconNavigation;
  readonly mainSubNavigation = this.#state.mainSubNavigation;
  readonly footerNavigation = this.#state.footerNavigation;
  readonly footerSubNavigation = this.#state.footerSubNavigation;
  readonly showMainNavigation = this.#state.showMainNavigation;

  updateLangSwitch(langSwitch: Array<LocalizedFragment>) {
    patchState(this.#state, {
      langSwitch,
    });
  }

  updateNavigation(result: ApolloQueryResult<GetNavigationQuery>) {
    patchState(this.#state, {
      mainNavigation: result.data?.mainNavigation ?? [],
      iconNavigation: result.data?.iconNavigation ?? [],
      mainSubNavigation: result.data?.mainSubNavigation ?? ([] as any),
      footerNavigation: result.data?.footerNavigation ?? ([] as any),
      footerSubNavigation: result.data?.footerSubNavigation ?? [],
    });
  }

  toggleMainNavigation(state?: boolean) {
    const _showMainNavigation = state ?? !this.#state.showMainNavigation();
    patchState(this.#state, {
      showMainNavigation: _showMainNavigation,
    });
  }

  constructor() {
    if (!isPlatformBrowser(this.#platformId)) return;
    effect(
      () => {
        const event = this.#routerEvents();
        if (event instanceof NavigationEnd) {
          if (history.state?.skipCloseMainNavigation) return;
          patchState(this.#state, {
            showMainNavigation: false,
          });
        }
      },
      { allowSignalWrites: true },
    );
  }
}

export function provideNavigationService(): ApplicationConfig['providers'] {
  return [NavigationService];
}
